import { queryHandler } from "../foundation/query.handler";
import React, { useEffect, useState } from "react";
import { scriptHandler } from "../foundation/script.handler";
import SEO from "../components/SEO";
import { LabeledInput } from "../tailwind/LabeledInput";
import { navigate } from "gatsby";
import { CONTACT_ESTIMATE_ENDPOINT } from "../foundation/url.constants";
import { faqHandler } from "../tailwind/faqs/faq.handler";
import { Navbar } from "../tailwind";
import { Footer } from "../tailwind/Footer";

const generateHeadings = ({ slug, name }) => ({
  primary:
    slug === "car"
      ? "Save up to $520/year - Free Auto Insurance Quotes"
      : `Compare Affordable ${name} Plans`,
  secondary: "Great! Your Neighborhood Qualifies for Discounts!",
});

export default ({
                  location,
                  pageContext: {
                    product: { slug, name, phone: verticalPhone, leadId, paths, articles, footer },
                  },
                }) => {
  const { utm_source, zip: initialZip, ...restQueryParams } = queryHandler(location);
  const displayQuotes = slug === "car" || slug === "health";

  const [formState, setFormState] = useState({
    utm_source,
    zip: initialZip,
    slug: location.pathname,
    host: "www.rateinsider.com",
  });

  useEffect(() => {
    if (displayQuotes)
      scriptHandler.mediaAlpha({
        placement_id: leadId,
        utm_source,
        zip: initialZip,
        type: "form",
        ...restQueryParams,
      });
  }, [leadId, displayQuotes, utm_source, initialZip]);

  useEffect(() => {
    scriptHandler.accordion();
  }, []);

  const updateData = data => setFormState(prevState => ({ ...prevState, ...data }));
  const handleChange = e => updateData({ [e.target.name]: e.target.value });

  const handleSubmit = e => {
    e.preventDefault();
    const { first_name, last_name, ...data } = formState;
    fetch(CONTACT_ESTIMATE_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        ...data,
        name: `${first_name} ${last_name}`,
      }),
    }).then(() => {
      navigate(`${paths.quotes}/post?utm_source=${utm_source}&zip=${initialZip}`);
    });
  };

  const headings = generateHeadings({ slug, name });
  const { first_name, last_name, address, city, state, zip, phone, email } = formState;

  return (
    <div style={{ backgroundColor: '#F9FBFD'}}>

    <SEO title={`${name} Quotes`} />
      <Navbar phoneNumber={verticalPhone}/>
      <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
        <div className="mt-4 p-4 md:px-8 md:py-4 rounded shadow-xl border bg-white">
          <div className="py-10 text-center">
            <h1 className="text-light-blue-500 text-2xl md:text-4xl font-semibold mb-2">{headings.primary}</h1>
            <h2 className="uppercase font-semibold text-gray-700 text-lg md:text-lg py-1">{headings.secondary}</h2>
          </div>
          <div>
            {displayQuotes ? (
              <div id="media-alpha" />
            ) : (
              <form id="quotes-form" onSubmit={handleSubmit}>
                <input type="hidden" name="utm_source" value={utm_source} />
                <input type="hidden" name="product" value={slug} />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
                  <LabeledInput
                    label="First Name"
                    name="first_name"
                    value={first_name}
                    onChange={handleChange}
                    placeholder="Enter your first name"
                  />
                  <LabeledInput
                    label="Last Name"
                    name="last_name"
                    value={last_name}
                    onChange={handleChange}
                    placeholder="Enter your last name"
                  />
                  <LabeledInput
                    label="Address"
                    name="address"
                    value={address}
                    onChange={handleChange}
                    placeholder="Enter your address name"
                  />
                  <LabeledInput
                    label="City"
                    name="city"
                    value={city}
                    onChange={handleChange}
                    placeholder="Enter city state"
                  />
                  <LabeledInput
                    label="State"
                    name="state"
                    value={state}
                    onChange={handleChange}
                    placeholder="Enter your state"
                  />
                  <LabeledInput
                    label="Zip"
                    name="zip"
                    value={zip}
                    onChange={handleChange}
                    placeholder="Enter your zip code"
                  />
                  <LabeledInput
                    label="Phone"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    placeholder="Enter your phone number"
                  />
                  <LabeledInput
                    label="Email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                  />
                </div>

                <button
                  type="submit"
                  className="mt-6 px-2 h-12 inline-flex items-center justify-center w-full mb-2 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-indigo-600 hover:bg-indigo-700 focus:shadow-outline focus:outline-none"
                >
                  Submit
                </button>
              </form>
            )}
            <br />
          </div>
        </div>
      </div>
      <div>
        {faqHandler(slug || "car").quotes()}
      </div>

      <Footer content={footer} />
    </div>
  );
};
