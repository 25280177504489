import React from "react";

export const LabeledInput = ({ label, ...inputProps }) => {
  const handleOnInvalid = e =>
    e.target.setCustomValidity(`Please enter your ${label.toLowerCase()}`);
  const handleOnInput = e => e.target.setCustomValidity("");
  return (
    <>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">{label}</label>
        <div className="mt-1">
          <input
            type="text"
            required
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            onInput={handleOnInput}
            onInvalid={handleOnInvalid}
            {...inputProps}
          />
        </div>
      </div>
    </>
  );
};
